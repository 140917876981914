import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout, Container, Title, Listing } from '../components'
import nicer from '../utils/nicer'

export default function Blog({ data }) {
  return (
    <Layout logo>
      <Container main>
        <Title>Blog</Title>
        <Listing compact items={nicer(data.posts)} />
      </Container>
    </Layout>
  )
}

Blog.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired
  }).isRequired
}

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { kind: { eq: "blog" } } }
    ) {
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
